import config from '../lib/config'
import request from '../lib/request'
import Service from '../lib/service'
import { cache } from '../lib/service-cache'
import { determineAvatar } from '../lib/service-helpers'
import {
  NOTIFICATION_TYPE_GUARDIAN_INVITE,
  NOTIFICATION_TYPE_ORPHAN_INVITE,
  NOTIFICATION_TYPE_ROSTER_INVITE,
  NOTIFICATION_METHOD_ACCEPT,
  NOTIFICATION_METHOD_DECLINE,
  NOTIFICATION_TYPE_TEAM_INVITE,
} from '../lib/constants'
import { setInterval } from 'timers';

const SERVICE_POLL_INTERVAL_SECONDS = 60 * 10 // 10 minutes

function extractRosterInfo(inviterName) {
  // extracts the roster info from the inviter string
  // example string inviterName = "Princeton Tigers(Princeton Youth Baseball)"
  // where roster_team_name = "Princeton Tigers" and organization_name = "Princeton Youth Baseball"

  const re = /^([^()]+)\(([^()]+)\)$/

  const matches = re.exec(inviterName)
  if (!matches) {
    if (inviterName === '') {
      return {}
    }
    return {
      roster_team_name: inviterName,
    }
  }

  return {
    roster_team_name: matches[1],
    organization_name: matches[2],
  }
}

let allNotifications
let orphanInvite

class NotificationsService extends Service {
  constructor() {
    super()
    window.addEventListener('message', this.onPostMessage, false)
  }
  acceptGuardianInvite(inviteId) { return request.put(`persona_listeners/${inviteId}/accept`) }

  declineOrphanInvite(inviteId) { return request.delete(`orphan_invites/${inviteId}`) }
  declineRosterInvite(inviteId) { return request.delete(`orphan_invites/${inviteId}`) }
  declineGuardianInvite(inviteId) { return request.delete(`persona_listeners/${inviteId}`) }

  submitNotificationAction({ id, type, method, uuid = nil }) {
    return Promise.resolve()
      .then(() => {
        if (method === NOTIFICATION_METHOD_ACCEPT) {
          if (type === NOTIFICATION_TYPE_GUARDIAN_INVITE) return this.acceptGuardianInvite(id)
        }
        if (method === NOTIFICATION_METHOD_DECLINE) {
          if (type === NOTIFICATION_TYPE_GUARDIAN_INVITE) return this.declineGuardianInvite(id)
          if (type === NOTIFICATION_TYPE_ORPHAN_INVITE) return this.declineOrphanInvite(id)
          if (type === NOTIFICATION_TYPE_ROSTER_INVITE) return this.declineRosterInvite(id)
        }
      })
  }

  getUserServiceInvites() {
    return request.get('invitations/mine', {
      baseURL: this.derivedBaseUrl,
      simpleHeaders: true, // avoid a preflight request
    })
  }

  getOrphanInvites() {
    return request.get('orphan_invites/mine', {
      baseURL: this.derivedBaseUrl,
      simpleHeaders: true, // avoid a preflight request
    })
  }

  getTeamInvites() {
    return request.get(`v3/roster_personas/invitations/mine`, {
      baseURL: this.derivedBaseUrl,
      simpleHeaders: true, // avoid a preflight request
    })
  }

  getPersonaListeners() {
    return request.get('persona_listeners', {
      params: {
        status: 'pending',
      },
    })
  }

  @cache({ expiresInSeconds: SERVICE_POLL_INTERVAL_SECONDS })
  getNotifications() {
    return Promise.all([
      this.getPersonaListeners(),
      this.getUserServiceInvites()
    ]).then((results) => {
      const [personaListeners, userServiceInvites] = results

      personaListeners.forEach((notification) => {
        notification.notification_type = NOTIFICATION_TYPE_GUARDIAN_INVITE
      })

      const promises = []
      const userServiceTeamInvites = userServiceInvites.filter((invite) => invite.originator_type === NOTIFICATION_TYPE_TEAM_INVITE)
      const userServiceOrphanInvites = userServiceInvites.filter((notification) => notification.originator_type === NOTIFICATION_TYPE_ORPHAN_INVITE)
      if (userServiceOrphanInvites) promises.push(this.getOrphanInvites())
      if (userServiceTeamInvites) promises.push(this.getTeamInvites())

      let orphanInvites = []
      let teamInvites = []
      return Promise.all(promises).then((inviteResponses) => {
        const [nginOrphanInvites, teamServiceInvites] = inviteResponses
        orphanInvites = this.convertOrphanInvites(userServiceOrphanInvites, nginOrphanInvites)
        teamInvites = this.convertTeamInvites(userServiceTeamInvites, teamServiceInvites)
      }).then(() => {
        const allNotifications = [].concat(personaListeners, teamInvites, orphanInvites)
        allNotifications.forEach((notification) => {
          notification.subject_avatar = notification.persona?.profile_images ? determineAvatar(
            notification.persona.profile_images
          ) : null
          notification.persona.name = notification.persona.name ?
            notification.persona.name : `${notification.persona.first_name} ${notification.persona.last_name}`
        })
        return allNotifications
      })
    })
  }

  convertOrphanInvites(userServiceInvites, nginInvites) {
    const adaptedInvites = []
    userServiceInvites.forEach((userServiceInvite) => {
      const matchingNginInvite = nginInvites.find((nginInvite) => nginInvite.id === Number(userServiceInvite.originator_id))
      if (matchingNginInvite.is_roster_player) {
        userServiceInvite.notification_type = NOTIFICATION_TYPE_ROSTER_INVITE
        Object.assign(
          userServiceInvite,
          extractRosterInfo(matchingNginInvite.inviter_name)
        )
      } else {
        userServiceInvite.notification_type = NOTIFICATION_TYPE_ORPHAN_INVITE
      }
      userServiceInvite.uuid = matchingNginInvite.uuid
      adaptedInvites.push(userServiceInvite)
    })
    return adaptedInvites
  }

  convertTeamInvites(userServiceInvites, teamServiceInvites) {
    const adaptedInvites = []
    userServiceInvites.forEach((userServiceInvite) => {
      const matchingTeamServiceInvite = teamServiceInvites.find((teamServiceInvite) => teamServiceInvite.id === userServiceInvite.originator_id)
      userServiceInvite.notification_type = NOTIFICATION_TYPE_TEAM_INVITE
      userServiceInvite.organization_name = userServiceInvite.organization.name
      userServiceInvite.uuid = userServiceInvite.originator_uuid
      userServiceInvite.team_name = matchingTeamServiceInvite?.team?.name
      adaptedInvites.push(userServiceInvite)
    })
    return adaptedInvites
  }

  onPostMessage(event) {
    const data = event.data || {}
    if (data.type === 'notification:resolved') {
      this.removeNotification(data.notificationType, data.notificationId)
    }
  }

  removeNotification(type, id) {
    const notifications = allNotifications || []
    const notificationIndex = notifications.findIndex(n => n.notification_type === type && n.id.toString() === id)
    if (notificationIndex === -1) return
    allNotifications.splice(notificationIndex, 1)
    this.getNotificationsSetCache(allNotifications)
  }
}

// since services are singletons we instantiate here
const notificationsService = new NotificationsService()

export default notificationsService
